import * as React from "react"
import { Link } from "gatsby"
import * as fuzzysearch from "fuzzysearch";
import Layout from "./layout"
import SEO from "./seo"

interface City {
  "city": string;
  "state": string;
  "lat": number;
  "lng": number;
}

interface Props {
  cities: City[];
}

interface State {
  search: string;
}

class CitiesIndex extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      search: ''
    }
  }

  susageCase(name: string): string {
    return name.split(' ').join('-').toLowerCase()
  }

  getPath(city: string): string {
    const cityName = this.susageCase(city)
    return `/scale-model-of-${cityName}.png`;
  }

  onSearch(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({search: event.target.value})
  }

  renderCity(city: City): JSX.Element | null {
    if(this.state.search.length > 1) {
      if (
        !fuzzysearch(this.state.search.toLowerCase(), city["city"].toLowerCase()) &&
        !fuzzysearch(this.state.search.toLowerCase(), city["state"].toLowerCase())
      ) {
        return null;
      }
    }
    return(
      <div
        className="card mb-3"
        key={`${this.susageCase(city["city"])}-${this.susageCase(city["state"])}`}
        style={{
          width: "250px"
        }}
      >
          {/* <img
            src={this.getPath(city["city"])}
            alt={`Scale architectural model of ${city["city"]}`}
            className="card-img-top img-fluid"
            style={{maxWidth: "400px"}}
          /> */}
          <div className="card-body">
            <h5 className="card-title mb-0 h6">
              <Link
                to={`/cities/${this.susageCase(city["city"])}-${this.susageCase(city["state"])}`}
                style={{width: "250px"}}
              >
                {city["city"]}, {city["state"]}
              </Link>
            </h5>
          </div>
      </div>
    )
  }

  render() {
    return (
      <Layout>
        <SEO title={`3D Printed Models of US Cities`}/>
        <div className="jumbotron" style={{backgroundColor: "#fff", padding: "2rem", marginBottom: 0}}>
          <div className="container">
            <h2 className="mb-4">Site Models of US Cities</h2>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search for city"
                onChange={this.onSearch.bind(this)}
                value={this.state.search}
                id="search"
              />
            </div>
            <div
              style={{
                display:"flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly"
              }}
            >
              {this.props.cities.map(this.renderCity.bind(this))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CitiesIndex
